import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useOnMount } from '@prophecy/utils/react/hooks';
import { parseStringDayOfMonth } from '@vangware/cron';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Stack } from '../Layout';
import { Select } from '../Select';
import { Text } from '../Typography/Text';
import { DatePicker } from './DatePicker';
import { TimePicker } from './TimePicker';
import { tokens } from './tokens';
import { CronType } from './types';
import { DaysFull, DaysInMonth, extractDayAndWeek, getCronDefaults, getRangeFromValues, getValuesFromCronPart, MonthFull, MonthHalf, toCronDayOfWeek, useTimeValues, Weeks } from './utils';
var RepeatType;
(function (RepeatType) {
    RepeatType["specific"] = "specific";
    RepeatType["variable"] = "variable";
})(RepeatType || (RepeatType = {}));
const RowLabel = styled(Text) `
  width: 48px;
  text-align: right;
`;
const StyledSelect = styled(Select) `
  width: 120px;
`;
const StyledDatePicker = styled(DatePicker) `
  min-width: 120px;
`;
export function YearTab({ cronType, cronValue, setCronValue }) {
    // extract day and week from the dayOfWeek
    const { day, week } = extractDayAndWeek(cronValue.dayOfWeek);
    // pick the first month only, as the year tab doesn't support multiple months
    const month = getValuesFromCronPart(cronValue.month, MonthHalf)[0];
    const dayOfMonth = getValuesFromCronPart(cronValue.dayOfMonth);
    const [repeatType, setRepeatType] = useState(RepeatType.specific);
    const { hours, minutes, handleTimeChange } = useTimeValues(cronValue, setCronValue, repeatType === RepeatType.specific ? ['month', 'dayOfMonth'] : ['month', 'dayOfWeek', 'dayOfMonth'], cronType);
    const cronDefaults = getCronDefaults(cronType);
    const defaultDayOfMonthWithWeek = cronType === CronType.quartz ? '?' : '*';
    const commonValues = useMemo(() => {
        return {
            month: cronValue.month,
            hours: hours,
            minutes: minutes
        };
    }, [cronValue.month, hours, minutes]);
    const handleMonthSelection = (month, dayKey) => {
        const dayOfPart = {
            [dayKey]: cronValue[dayKey]
        };
        setCronValue(Object.assign(Object.assign(Object.assign(Object.assign({}, cronDefaults), commonValues), { month: month, 
            // day of Month need to be provided always
            dayOfMonth: defaultDayOfMonthWithWeek }), dayOfPart));
    };
    const handleDateSelection = (dates) => {
        var _a;
        const rangeString = getRangeFromValues(dates, DaysInMonth);
        const cronMonthDays = (_a = parseStringDayOfMonth(rangeString)) !== null && _a !== void 0 ? _a : '*';
        setCronValue(Object.assign(Object.assign(Object.assign({}, cronDefaults), commonValues), { dayOfMonth: cronMonthDays }));
    };
    const handleWeekSelection = (_week) => {
        setCronValue(Object.assign(Object.assign({}, cronDefaults), { dayOfWeek: toCronDayOfWeek(day !== null && day !== void 0 ? day : 1, _week), dayOfMonth: defaultDayOfMonthWithWeek }));
    };
    const handleWeekDaySelection = (_day) => {
        setCronValue(Object.assign(Object.assign({}, cronDefaults), { dayOfWeek: toCronDayOfWeek(_day, week), dayOfMonth: defaultDayOfMonthWithWeek }));
    };
    useOnMount(() => {
        if (!month && !day && !week) {
            setCronValue(Object.assign(Object.assign(Object.assign({}, cronDefaults), commonValues), { month: 1, dayOfMonth: [1] }));
        }
    });
    return (_jsxs(Stack, { gap: tokens.CronGenerator.Fields.gap, children: [_jsxs(Stack, { direction: 'horizontal', height: '40px', alignY: 'center', gap: tokens.CronGenerator.Fields.gap, children: [_jsx(RowLabel, { level: 'sm', children: "Repeat" }), _jsxs(StyledSelect, { value: repeatType, onChange: setRepeatType, disabled: cronType === CronType.unix, children: [_jsx(Select.Option, { value: RepeatType.specific, children: "every" }), _jsx(Select.Option, { value: RepeatType.variable, children: "on the" })] }), repeatType === RepeatType.specific && (_jsxs(_Fragment, { children: [_jsx(StyledSelect, { value: month, options: MonthFull.map((month, index) => ({ label: month, value: index + 1 })), onChange: (month) => handleMonthSelection(month, 'dayOfMonth'), virtual: false, dropdownMatchSelectWidth: false }), _jsx(StyledDatePicker, { mode: 'multiple', value: dayOfMonth, onChange: handleDateSelection })] })), repeatType === RepeatType.variable && (_jsxs(_Fragment, { children: [_jsx(StyledSelect, { value: week, options: Weeks.map((week, index) => ({ label: week, value: index + 1 })), onChange: handleWeekSelection }), _jsx(StyledSelect, { value: day, options: DaysFull.map((day, index) => ({ label: day, value: index + 1 })), onChange: handleWeekDaySelection, virtual: false, dropdownMatchSelectWidth: false }), _jsx(Text, { level: 'sm', children: "of" }), _jsx(StyledSelect, { value: month, options: MonthFull.map((month, index) => ({ label: month, value: index + 1 })), onChange: (month) => handleMonthSelection(month, 'dayOfWeek'), virtual: false, dropdownMatchSelectWidth: false })] }))] }), _jsxs(Stack, { direction: 'horizontal', alignY: 'center', gap: tokens.CronGenerator.Fields.gap, children: [_jsx(RowLabel, { level: 'sm', children: "at" }), _jsx(TimePicker, { value: [hours, minutes], onChange: handleTimeChange })] })] }));
}
