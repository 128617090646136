import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { useClickAwayListener, usePersistentCallback } from '@prophecy/utils/react/hooks';
import { format } from 'date-fns';
import { ClockIcon } from '../../Icons';
import { Popover } from '../../Popover';
import { DEFAULT_TIME_FORMAT } from '../constant';
import { useInternalValue, usePickerInput, useValNotFinalizedYet } from '../hooks';
import { TimePicker } from '../pickerVariants/TimePicker';
import { StyledInput } from '../styled';
import { isTimeValid } from '../utils/utils';
export const TimeInput = function (_a) {
    var { pickerProps, popoverProps, showActionFooter = false } = _a, props = __rest(_a, ["pickerProps", "popoverProps", "showActionFooter"]);
    const _b = pickerProps || {}, { timeFormat = DEFAULT_TIME_FORMAT } = _b, restTimePickerProps = __rest(_b, ["timeFormat"]);
    const { value: val, onChange: setVal, sync, reset, valSynced } = useInternalValue({
        value: props.value,
        onChange: props.onChange,
        autoSync: !showActionFooter
    });
    const getTimeString = usePersistentCallback(function (date) {
        return date ? format(date, timeFormat) : '';
    });
    const { popoverOpen, containerRef, iconRef: clockIconContainerRef, handleInputChange, handleSelect, handlePopupOutsideInterraction, onApply, onCancel, inputString: timeString } = usePickerInput({
        format: timeFormat,
        onChange: setVal,
        isValid: (val, format) => isTimeValid(val, format),
        sync,
        reset,
        value: val,
        getValueString: getTimeString
    });
    const valNotFinalizedYet = useValNotFinalizedYet({
        showActionFooter,
        valSynced,
        popoverOpen
    });
    const onInterractionOutsideRef = useClickAwayListener(handlePopupOutsideInterraction);
    const popover = (_jsx(Popover, Object.assign({ overlay: _jsx("div", { ref: onInterractionOutsideRef, children: _jsx(TimePicker, Object.assign({}, restTimePickerProps, { value: val, onChange: (val) => handleSelect(val), timeFormat: timeFormat, onApply: onApply, onCancel: onCancel, showActionFooter: showActionFooter })) }), overlayStyle: { border: 'none', boxShadow: 'none', backgroundColor: 'transparent' } }, popoverProps, { visible: popoverOpen, onInteractOutside: handlePopupOutsideInterraction, children: _jsx("div", { ref: clockIconContainerRef, children: _jsx(ClockIcon, { type: 'default' }) }) })));
    return (_jsx("div", { ref: containerRef, children: _jsx(StyledInput, Object.assign({}, props, { value: timeString, onChange: handleInputChange, placeholder: timeFormat.toLocaleLowerCase(), icon: popover, iconPlacement: 'right', "$valNotFinalizedYet": valNotFinalizedYet })) }));
};
