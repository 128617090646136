import { useLazyEffect, useOnMount, usePersistentCallback } from '@prophecy/utils/react/hooks';
import { parse } from 'date-fns';
import { isEqualWith } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { DATA_ATTR_CLEAR } from './tokens';
export function useDatePopupInterraction(toggleIconRef, containerRef, togglePopover) {
    const isCancelButtonActive = () => { var _a; return ((_a = toggleIconRef.current) === null || _a === void 0 ? void 0 : _a.dataset['action']) === DATA_ATTR_CLEAR; };
    useOnMount(function () {
        const container = containerRef.current;
        const inputContainer = container === null || container === void 0 ? void 0 : container.querySelector('[type="text"]');
        function handleInputClick() {
            if (isCancelButtonActive())
                return;
            togglePopover(true);
        }
        inputContainer.addEventListener('click', handleInputClick);
        return function () {
            inputContainer.removeEventListener('click', handleInputClick);
        };
    });
    const handlePopupOutsideInterraction = function (e) {
        var _a;
        if (!e.target.parentElement) {
            return;
        }
        const target = e.target;
        const clickedAnywhereInRangeInputContainer = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.contains(target);
        if (!clickedAnywhereInRangeInputContainer) {
            togglePopover(false);
        }
    };
    return { handlePopupOutsideInterraction };
}
export function useInternalValue({ value, defaultValue, onChange, autoSync, isRangePicker }) {
    const [internalState, setInternalState] = useState(value !== null && value !== void 0 ? value : defaultValue);
    const syncValue = usePersistentCallback((val) => {
        if (autoSync && onChange) {
            onChange(val);
        }
    });
    useLazyEffect(() => {
        syncValue(internalState);
    }, [autoSync]);
    useEffect(() => {
        setInternalState(value);
    }, [value]);
    const handleChange = usePersistentCallback((val) => {
        setInternalState(val);
        syncValue(val);
    });
    const sync = usePersistentCallback((value = internalState) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(value);
    });
    const reset = usePersistentCallback(() => {
        setInternalState(value);
    });
    const valSynced = isRangePicker
        ? (value === null || value === void 0 ? void 0 : value[0]) && (value === null || value === void 0 ? void 0 : value[1])
            ? isEqualWith(value, internalState)
            : false
        : value
            ? isEqualWith(value, internalState)
            : false;
    return { value: internalState, onChange: handleChange, sync, reset, valSynced };
}
export function usePickerInput({ format, onChange, isValid, sync, reset, value, getValueString, isRangePicker }) {
    const [popoverOpen, togglePopoverOpen] = useState(false);
    const containerRef = useRef(null);
    const iconRef = useRef(null);
    const getDefaultInputString = function () {
        return (isRangePicker ? ['', ''] : '');
    };
    const [inputString, setInputString] = useState(getDefaultInputString());
    useEffect(() => {
        setInputString(getValueString(value));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, popoverOpen]);
    const handleInputChange = usePersistentCallback((val) => {
        setInputString(val);
        const isValidInput = isValid(val, format);
        if (val === '') {
            resetDateToNull();
            sync(null);
        }
        else if (isValidInput) {
            const parsedDate = parse(val, format, new Date());
            onChange === null || onChange === void 0 ? void 0 : onChange(parsedDate);
            sync(parsedDate);
        }
    });
    const handleRangeInputChange = function (strVal, isStartDate = false) {
        // TODO: Add support for range change through input
        setInputString((prevState) => (isStartDate ? [strVal, prevState[1]] : [prevState[0], strVal]));
        const isValidInput = isValid(strVal, format);
        if (isValidInput) {
            const parsedDate = parse(strVal, format, new Date());
            const val = value;
            const finalVal = isStartDate ? [parsedDate, val === null || val === void 0 ? void 0 : val[1]] : [val === null || val === void 0 ? void 0 : val[0], parsedDate];
            onChange === null || onChange === void 0 ? void 0 : onChange(finalVal);
        }
    };
    const handleSelect = usePersistentCallback((date) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(date);
    });
    const resetDateToNull = usePersistentCallback(() => {
        onChange === null || onChange === void 0 ? void 0 : onChange(null);
        setInputString(getDefaultInputString());
    });
    const togglePopover = usePersistentCallback((value) => {
        togglePopoverOpen(value);
        if (value === false || (typeof value === 'function' && value(popoverOpen) === false)) {
            reset();
        }
    });
    const { handlePopupOutsideInterraction } = useDatePopupInterraction(iconRef, containerRef, togglePopover);
    const onApply = usePersistentCallback(() => {
        togglePopoverOpen(false);
        sync();
    });
    const onCancel = usePersistentCallback(() => {
        togglePopover(false);
    });
    return {
        popoverOpen,
        togglePopover,
        containerRef,
        iconRef,
        handleInputChange,
        handleSelect,
        onApply,
        onCancel,
        handlePopupOutsideInterraction,
        inputString,
        handleRangeInputChange,
        resetDateToNull
    };
}
/*
 final value is the value that is currently selected,
 if use changes the value, new value is stored as temp value
 and until user press apply btn, that value is not finalized
*/
export function useValNotFinalizedYet({ showActionFooter, valSynced, popoverOpen }) {
    return showActionFooter && !valSynced && popoverOpen;
}
