import styled, { createGlobalStyle } from 'styled-components';
import { Button } from '../Button';
import { Input } from '../Input';
import { theme } from '../theme';
import { tokens, PREFIX_CLASS, TIME_PANEL_CLASS, HEADER_CLASS, HEADER_VIEW_CLASS, CONTENT_CLASS, TIME_PANEL_COLUMN_CLASS, TIME_PANEL_CELL_INNER_CLASS, TIME_PANEL_CELL_SELECTED_CLASS, RANGES_CLASS, PANEL_LAYOUT_CLASS, PANELS_CLASS, PANEL_CLASS, HEADER_SUPER_NEXT_BTN_CLASS, HEADER_SUPER_PREV_BTN_CLASS, HEADER_PREV_BTN_CLASS, HEADER_NEXT_BTN_CLASS, BODY_CLASS, CELL_RANGE_START_CLASS, CELL_RANGE_END_CLASS, CELL_TODAY_CLASS, PRESETS_CLASS, DROPDOWN_CLASS, RANGE_ARROW_CLASS, DECADE_BTN_CLASS, DATE_TIME_PANEL_CLASS } from './tokens';
import { checkPanelMode } from './utils/utils';
const timePickerStyle = `
  .${TIME_PANEL_CLASS} {
    max-height: ${tokens.Calendar.body.height};
    height: ${tokens.Calendar.body.height};
    margin-left: 24px;
    padding: 0 24px;
    border: none;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      height: calc(100% + 72px); /* including padding */
      width: 0;
      transform: translateY(-45.8%);
      border: ${tokens.TimePickerContainer.Panel.border};
    }

    .${CONTENT_CLASS} {
      max-height: ${tokens.Calendar.body.height};
      position: relative;
      display: flex;
      direction: ltr;
    }
  }

  .${HEADER_CLASS} {
    font-family: ${tokens.TimePickerContainer.Panel.Header.fontFamily};
    font-size: ${tokens.TimePickerContainer.Panel.Header.fontSize};
    font-weight: ${tokens.TimePickerContainer.Panel.Header.fontWeight};
    line-height: ${tokens.TimePickerContainer.Panel.Header.lineHeight};
    color: ${tokens.TimePickerContainer.Panel.Header.color};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .${HEADER_VIEW_CLASS} {
    height: ${tokens.Calendar.Header.height};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .${TIME_PANEL_COLUMN_CLASS} {
    flex: auto;
    width: 50px;
    margin: 0;
    padding: 0 0 180px;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 12px;
    text-align: left;
    list-style: none;
    transition: background 0.3s;
  }

  .${TIME_PANEL_CELL_INNER_CLASS} {
    font-family: ${tokens.TimePickerContainer.Panel.Cells.fontFamily};
    font-size: ${tokens.TimePickerContainer.Panel.Cells.fontSize};
    font-weight: ${tokens.TimePickerContainer.Panel.Cells.fontWeight};
    line-height: ${tokens.TimePickerContainer.Panel.Cells.lineHeight};
    height: ${tokens.TimePickerContainer.Panel.Cells.height};
    width: ${tokens.TimePickerContainer.Panel.Cells.width};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  li {
    color: ${tokens.TimePickerContainer.Panel.Cells.color};
    
    &.${TIME_PANEL_CELL_SELECTED_CLASS} {
      background-color: ${tokens.TimePickerContainer.Panel.SelectedCells.backgroundColor};
      color: ${tokens.TimePickerContainer.Panel.SelectedCells.color};
    }

    &:hover {
      cursor: pointer;
      background-color: ${tokens.TimePickerContainer.Panel.Cells.States.Hovered.backgroundColor};
      color: ${tokens.TimePickerContainer.Panel.Cells.States.Hovered.color};
    }
  }
`;
export const StyledCalendar = styled.div `
  border: ${tokens.Container.border};
  box-shadow: ${tokens.Container.boxShadow};
  border-radius: ${tokens.Container.borderRadius};
  overflow: hidden;

  .${RANGES_CLASS} {
    display: none;
  }

  ${(props) => props.isRangePicker
    ? `
    .${PANEL_LAYOUT_CLASS} {
      height: ${tokens.StyledDateRangePickerContainer.height};
      position: relative;
    }

    .${PANELS_CLASS} {
      display: flex;
      align-items: center;
      position: relative;
    }

    .${PANEL_CLASS}:first-of-type {
      border-right: .5px solid ${tokens.Calendar.Range.Seperator.color};
      border-top-left-radius: ${props.showPresets ? 0 : tokens.Container.borderRadius};
      border-bottom-left-radius: ${props.showActionFooter ? 0 : tokens.Container.borderRadius};
    }

    .${PANEL_CLASS}:last-of-type {
      border-top-right-radius: ${tokens.Container.borderRadius};
      border-bottom-right-radius: ${props.showActionFooter ? 0 : tokens.Container.borderRadius};
      border-left: .5px solid ${tokens.Calendar.Range.Seperator.color};
    }
    `
    : ''}

  .${PANEL_CLASS} {
    width: ${tokens.Calendar.panel.width};
    background-color: ${tokens.Container.background};
    border: none;
    padding: ${tokens.Calendar.padding};

    ${(props) => `
    border-radius: ${props.isRangePicker ? 0 : tokens.Container.borderRadius};
      ${props.showActionFooter
    ? `
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          `
    : ''}
      `}

  .${DATE_TIME_PANEL_CLASS} {
    display: flex;
  }

  .${HEADER_CLASS} {
    height: ${tokens.Calendar.Header.height};
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .${HEADER_SUPER_NEXT_BTN_CLASS}, .${HEADER_SUPER_PREV_BTN_CLASS} {
    display: ${(props) => checkPanelMode('year', props.mode) ? 'block' : 'none'};
  }

  .${HEADER_PREV_BTN_CLASS},
    .${HEADER_NEXT_BTN_CLASS},
    .${HEADER_SUPER_NEXT_BTN_CLASS},
    .${HEADER_SUPER_PREV_BTN_CLASS} {
    border: none;
    outline: none;
    background-color: transparent;
    width: ${tokens.Calendar.Header.NavBtn.width};
    height: ${tokens.Calendar.Header.NavBtn.height};
    padding: ${tokens.Calendar.Header.NavBtn.padding};
    border-radius: ${tokens.Calendar.Header.NavBtn.borderRadius};

    &:hover {
      cursor: pointer;
    }
  }

  .${HEADER_VIEW_CLASS} {
    min-width: ${tokens.Calendar.Header.View.width};
    height: ${tokens.Calendar.Header.View.height};
    text-align: center;

    .${DECADE_BTN_CLASS} {
      pointer-events: none;
    }

    button {
      background-color: transparent;
      border: none;
      outline: none;
      font-family: ${tokens.Calendar.Header.View.MonthYearContainer.fontFamily};
      font-size: ${tokens.Calendar.Header.View.MonthYearContainer.fontSize};
      font-weight: ${tokens.Calendar.Header.View.MonthYearContainer.fontWeight};
      line-height: ${tokens.Calendar.Header.View.MonthYearContainer.lineHeight};
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      font-variation-settings: 'slnt' 0;
      color: ${tokens.Calendar.Header.View.MonthYearContainer.color};
    }
  }

  .${BODY_CLASS} {
    width: ${tokens.Calendar.body.width};
    height: ${tokens.Calendar.body.height};
  }

  .${CONTENT_CLASS} {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    height: ${(props) => checkPanelMode(['month', 'year'], props.mode) ? '100%' : 'auto'};

    td,
    th {
      margin: 0;
      padding: 1.7px 0; /* added static padding*/
      height: ${tokens.Calendar.body.ContentTable.Cells.height};
      width: ${tokens.Calendar.body.ContentTable.Cells.width};
    }

    td {
      position: relative;
    }

    th {
      font-family: ${tokens.Calendar.body.ContentTable.HeaderCells.fontFamily};
      font-size: ${tokens.Calendar.body.ContentTable.HeaderCells.fontSize};
      line-height: ${tokens.Calendar.body.ContentTable.HeaderCells.lineHeight};
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      font-weight: ${tokens.Calendar.body.ContentTable.HeaderCells.fontWeight};
      font-variation-settings: 'slnt' 0;
      color: ${tokens.Calendar.body.ContentTable.HeaderCells.color};
    }

    td:hover {
      cursor: pointer;
    }
  }

  .${CELL_RANGE_START_CLASS}, .${CELL_RANGE_END_CLASS} {
    &.${CELL_TODAY_CLASS} > div::after {
      background-color: white;
    }
  }

  ${timePickerStyle}

  .${PREFIX_CLASS}-time-panel {
    padding-right: 0;
  }

  /* presets styles */
  .${PRESETS_CLASS} {
    width: 192px;
    height: ${(props) => props.showActionFooter ? tokens.Presets.height : tokens.StyledDateRangePickerContainer.height};
    padding: ${tokens.Presets.padding};
    gap: ${tokens.Presets.gap};
    background-color: #fff;
    border-right: 1px solid ${tokens.Calendar.Range.Seperator.color};
    overflow-y: scroll;
    overflow-x: hidden;
    text-overflow: ellipsis;
    border-top-left-radius: ${tokens.Container.borderRadius};
    border-bottom-left-radius: ${tokens.Container.borderRadius};

    li {
      width: 160px;
      height: 40px;
      padding: ${tokens.Presets.Items.padding};
      border-radius: ${tokens.Presets.Items.borderRadius};
      font-family: ${tokens.Presets.Items.fontFamily};
      font-size: ${tokens.Presets.Items.fontSize};
      font-weight: ${tokens.Presets.Items.fontWeight};
      line-height: ${tokens.Presets.Items.lineHeight};
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      font-variation-settings: 'slnt' 0;
      color: ${tokens.Presets.Items.color};
      word-wrap: break-word;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        cursor: pointer;
        background: ${tokens.Presets.Items.States.Hover.backgroundColor};
      }
    }
  }
`;
export const StyledCalendarAction = styled.div `
    display:flex;
    align-items: center;
    justify-content:flex-start;
    width: ${(props) => (props.miniMode ? 'auto' : '328px')};328px;
    height:72px;
    padding: ${tokens.PickerAction.padding};
    gap: ${tokens.PickerAction.gap};
    border-top: ${tokens.PickerAction.borderTop};
    background: ${theme.colors.white};

    button {
        width: ${(props) => (props.miniMode ? '79px' : '142px')};
        height: 40px;
        padding: ${tokens.PickerAction.Button.padding};
        gap: ${tokens.PickerAction.Button.gap};
        border-radius: ${tokens.PickerAction.Button.borderRadius};
        font-family: ${tokens.PickerAction.Button.fontFamily};
        font-size: ${tokens.PickerAction.Button.fontSize};
        font-weight: ${tokens.PickerAction.Button.fontWeight};
        line-height: ${tokens.PickerAction.Button.lineHeight};
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        font-variation-settings: 'slnt' 0;
        box-shadow: ${tokens.PickerAction.Button.boxShadow};
    }
`;
export const StyledCalednarActionButton = styled(Button) `
  ${(props) => !props.cancel
    ? `
            color: ${tokens.PickerAction.Button.Cancel.color};
            border: ${tokens.PickerAction.Button.Cancel.border};
            background-color: transparent;
        `
    : `
            background-color: ${tokens.PickerAction.Button.Apply.backgroundColor};
            border: 2px solid;
            border-image-source: ${tokens.PickerAction.Button.Apply.borderImageSource};
        `}
`;
export const StyledDateCell = styled.div `
  width: ${tokens.DateCell.height};
  height: ${tokens.DateCell.width};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-family: ${tokens.DateCell.fontFamily};
  font-size: ${tokens.DateCell.fontSize};
  line-height: ${tokens.DateCell.lineHeight};
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  font-weight: ${tokens.DateCell.fontWeight};
  font-variation-settings: 'slnt' 0;
  color: ${tokens.DateCell.color};
  position: relative;
  z-index: 1;

  ${(props) => !props.isCellInView
    ? `
            color: ${props.isRangePicker
        ? `${tokens.DateCell.NotInView.color} !important`
        : props.selected
            ? tokens.DateCell.Selected.color
            : tokens.DateCell.NotInView.color};
            font-weight: ${tokens.DateCell.NotInView.fontWeight};
            background-color: ${props.isRangePicker
        ? 'transparent !important'
        : props.selected
            ? tokens.DateCell.Selected.backgroundColor
            : 'transparent'};
            ${props.hasEvents && props.selected
        ? `
                background-color: ${tokens.DateCell.NotInView.HasEvents.backgroundColor};
                &:hover{
                    background: ${tokens.DateCell.States.Hovered.backgroundColor};
                    color: ${tokens.DateCell.States.Hovered.color};
                }
                &::after{
                    background-color: ${tokens.DateCell.NotInView.HasEvents.Dot.backgroundColor};
                }
            `
        : ''}
        `
    : ''}

  ${(props) => props.selected
    ? `
                background-color: ${tokens.DateCell.Selected.backgroundColor};
                color: ${tokens.DateCell.Selected.color};

            `
    : `
                &:hover{
                  ${!props.isRangePicker
        ? ` background: ${tokens.DateCell.States.Hovered.backgroundColor};
                    color: ${tokens.DateCell.States.Hovered.color};`
        : ''}
                }
            `}

    ${(props) => props.hasEvents
    ? `
            position: relative;

            &::after{
                content:'';
                position:absolute;
                height:5px;
                width:5px;
                background-color: ${tokens.DateCell.HasEvents.backgroundColor};
                top:31px;
                left: 17.5px;  //AMK_TODO: // find out why I needed to add this padding
                border-radius: 50%;
            }
            ${props.selected
        ? ` 
                        background-color:  ${tokens.DateCell.HasEvents.Selected.backgroundColor};
                        color:${tokens.DateCell.HasEvents.Selected.color};
                        &::after{
                            background-color: ${tokens.DateCell.HasEvents.Selected.Dot.color};
                        }`
        : ` background: ${tokens.DateCell.HasEvents.color};`}
        `
    : ``}  

    ${(props) => props.isToday
    ? ` 
            position: relative;
            &::after{
                content:'';
                position:absolute;
                height:5px;
                width:5px;
                background-color: ${props.selected ? '#fff' : tokens.DateCell.HasEvents.backgroundColor};
                top:31px;
                left: 17.5px;  //AMK_TODO: // find out why I needed to add this padding
                border-radius: 50%;
            }
        `
    : ''}
    
    ${(props) => props.isRangePicker && !props.selectedRangeValid
    ? `
            &:hover{
                background-color:  ${tokens.DateCell.HasEvents.Selected.backgroundColor};
                color:${tokens.DateCell.HasEvents.Selected.color};
            }
        `
    : ''}
`;
export const StyledTimeCell = styled.div `
  font-family: ${tokens.TimePickerContainer.Panel.Cells.fontFamily};
  font-size: ${tokens.TimePickerContainer.Panel.Cells.fontSize};
  font-weight: ${tokens.TimePickerContainer.Panel.Cells.fontWeight};
  line-height: ${tokens.TimePickerContainer.Panel.Cells.lineHeight};
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  font-variation-settings: 'slnt' 0;
  height: ${tokens.TimePickerContainer.Panel.Cells.height};
  width: ${tokens.TimePickerContainer.Panel.Cells.width};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.5px 0px;
`;
export const RangeEndPoint = styled.div `
  ${(props) => props.isSelectedRange && props.isCellInView
    ? `
            position: absolute;
            height: 40px;
            width: 40px;
            background-color: ${props.isSelectedRange === 'end' && props.isSunday ? 'transparent' : tokens.DateCell.Range.color};
            border-radius: ${props.isSelectedRange === 'middle'
        ? props.isFirstDayOfMonth || props.isSunday
            ? '50% 0 0 50%'
            : props.isLastDayOfMonth || props.isSaturday
                ? '0 50% 50% 0'
                : ''
        : props.isSelectedRange === 'start'
            ? '50% 0 0 50%'
            : props.isSelectedRange === 'end'
                ? '0 50% 50% 0'
                : '0'};
        `
    : ``}

  ${(props) => props.isStartOfRange || props.isEndOfRange || ['start', 'end'].includes(props.isSelectedRange)
    ? `
                +div,
                +div:hover{
                    background-color:  ${tokens.DateCell.HasEvents.Selected.backgroundColor};
                    color:${tokens.DateCell.HasEvents.Selected.color};
                    
                }
            `
    : ''}
`;
export const OptimisticRange = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  height: 40px;
  width: 40px;
  transform: translate(-50%, -50%);
  ${(props) => props.isSelectedRange === 'end' || props.isSelectedRange === 'start'
    ? `&::after{
          content: '';
          position:absolute;
          top:50%;
          left:50%;
          height: calc(100% - 4px);
          width: calc(100% - 4px);
          border:2px dashed ${tokens.DateCell.HasEvents.Selected.backgroundColor};
          border-radius: 50%; 
          transform: translate(-50%, -50%); 
        }`
    : 'none'};
  background-color: ${tokens.DateCell.Range.color};

  &::after {
  }

  ${(props) => `border-radius: ${props.isSelectedRange === 'middle'
    ? props.isFirstDayOfMonth || props.isSunday
        ? '50% 0 0 50%'
        : props.isLastDayOfMonth || props.isSaturday
            ? '0 50% 50% 0'
            : ''
    : props.isSelectedRange === 'start'
        ? '50% 0 0 50%'
        : props.isSelectedRange === 'end'
            ? '0 50% 50% 0'
            : '0'};`}
`;
export const StyledTimePanel = styled.div `
  ${timePickerStyle}

  border-radius: ${tokens.TimePickerContainer.Panel.borderRadius};
  box-shadow: ${tokens.TimePickerContainer.Panel.boxShadow};
  border: ${tokens.TimePickerContainer.Panel.border};
  background-color: ${tokens.TimePickerContainer.Panel.background};

  .${PREFIX_CLASS}-panel {
    border-radius: ${tokens.TimePickerContainer.Panel.borderRadius};
    box-shadow: none;
    border: none;
    background-color: ${tokens.TimePickerContainer.Panel.background};
    padding: ${tokens.TimePickerContainer.Panel.padding};
  }

  .${PREFIX_CLASS}-time-panel {
    margin: 0;
    padding: 0;

    &::after {
      border: none;
    }
  }

  .${PREFIX_CLASS}-footer {
    display: none;
  }
`;
export const StyledPickerContainer = styled.div `
  ${(props) => props.renderAsPanel
    ? `
            .${PREFIX_CLASS}{
                height: 0px;
                width: 0px;
                opacity:0;
                overflow: hidden;
            }
        `
    : ``}
`;
export const PickerDropdownBorderEraser = createGlobalStyle `
  .${DROPDOWN_CLASS} {
    box-shadow: none;
    height: 0;
    width: 0;
    overflow: hidden;
  }

  .${RANGE_ARROW_CLASS}{
    display: none;
  }
`;
export const StyledDateRangeInput = styled.div `
  display: flex;
  gap: 8px;
  align-items: center;

  .date-range-input-child-input {
    & > div {
      border-radius: 0;
      box-shadow: none;
      border: none;

      input {
        text-align: center;
      }
    }
  }
`;
export const StyledPanelCellRederer = styled.div `
  height: ${tokens.DateCell.width};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-family: ${tokens.DateCell.fontFamily};
  font-size: ${tokens.DateCell.fontSize};
  line-height: ${tokens.DateCell.lineHeight};
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  font-weight: ${tokens.DateCell.fontWeight};
  font-variation-settings: 'slnt' 0;
  color: ${tokens.DateCell.color};
  position: relative;
  z-index: 1;
  width: 96%;
  margin: 2% 0;

  ${(props) => props.selected
    ? `
        background-color:  ${tokens.DateCell.HasEvents.Selected.backgroundColor};
        color:${tokens.DateCell.HasEvents.Selected.color};
      `
    : ``}

  &:hover {
    background: ${tokens.DateCell.States.Hovered.backgroundColor};
    color: ${tokens.DateCell.States.Hovered.color};
  }
`;
export const StyledInput = styled(Input) `
  input {
    color: ${(props) => (props.$valNotFinalizedYet ? tokens.Input.Placeholder.color : 'inherit')};
  }
`;
